<template>
	<div>
		<Dialog :dialog="serviceDialog" :dialog-width="dialogWidth">
			<template v-slot:title>Service Schedule for {{ type }}</template>
			<template v-slot:body>
				<v-form
					ref="serviceForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="onSubmit"
				>
					<v-row class="service-dialog">
						<v-col md="4" class="my-auto py-0">
							<label :for="`expected-start-date-${uniqFieldId}`" class="btx-label mt-3"
								>Expected Start Date</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<DateTimePicker
								hide-details
								:min-date="today"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`expected-start-date-${uniqFieldId}`"
								placeholder="Expected Start Date"
								v-model="service.start_date"
								v-on:change="updateDates()"
							></DateTimePicker>
						</v-col>
						<v-col md="4" class="my-auto py-0">
							<label :for="`expected-end-date-${uniqFieldId}`" class="btx-label mt-3"
								>Expected End Date</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<DateTimePicker
								hide-details
								:min-date="service.start_date"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`expected-end-date-${uniqFieldId}`"
								placeholder="Expected End Date"
								v-model="service.end_date"
							></DateTimePicker>
						</v-col>
						<v-col md="4" class="py-0"> </v-col>
						<v-col md="8" class="py-0">
							<v-checkbox
								dense
								hide-details
								label="Put item into maintenance and make it unavailable at the starting date."
								class="mt-3 mb-0 p-0"
								v-model="service.maintenance"
							></v-checkbox>
						</v-col>
						<v-col md="4" class="my-auto py-0">
							<label for="reason" class="btx-label mt-3 required">Service Type</label>
						</v-col>
						<v-col md="8" class="py-0">
							<AutoCompleteInput
								hide-details
								:items="serviceTypeList"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`service-type-${uniqFieldId}`"
								placeholder="Service Type"
								v-model="service.service_type"
								:rules="[vrules.required(service.service_type, 'Service Type')]"
								:class="{
									required: !service.service_type,
								}"
								v-on:click:append-outer="manageServiceTypeDialog = true"
								append-outer-icon="mdi-cog"
							></AutoCompleteInput>
						</v-col>
						<v-col md="4" class="my-auto py-0">
							<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3"
								>Service Performed by</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<RadioInput
								row
								hide-details
								v-model="service.performed_by"
								:disabled="pageLoading"
								:id="`service-performed-by-${uniqFieldId}`"
								:items="performedItems"
							></RadioInput>
						</v-col>
						<v-col md="4" class="my-auto py-0">
							<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3"
								>Service Location</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<RadioInput
								row
								hide-details
								v-model="service.location_by"
								:disabled="pageLoading"
								:id="`service-performed-by-${uniqFieldId}`"
								:items="performedPlaceItems"
							></RadioInput>
						</v-col>
						<template v-if="service.performed_by == 1">
							<v-col md="4" class="my-auto py-0">
								<label :for="`vendor-${uniqFieldId}`" class="btx-label mt-3">Supplier</label>
							</v-col>
							<v-col md="8" class="py-0">
								<AutoCompleteInput
									hide-details
									:items="vendorList"
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`vendor-${uniqFieldId}`"
									placeholder="Supplier"
									v-model="service.vendor"
								></AutoCompleteInput>
							</v-col>
						</template>
						<template v-else>
							<v-col md="4" class="my-auto py-0">
								<label :for="`member-${uniqFieldId}`" class="btx-label mt-3">Staff</label>
							</v-col>
							<v-col md="8" class="py-0">
								<AutoCompleteInput
									hide-details
									:items="memberList"
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`member-${uniqFieldId}`"
									placeholder="Staff"
									v-model="service.member"
								></AutoCompleteInput>
							</v-col>
						</template>
						<v-col md="4" class="py-0">
							<label for="description" class="btx-label mt-3">Description</label>
						</v-col>
						<v-col md="8" class="py-0">
							<TextAreaInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="description"
								placeholder="Description"
								v-model="service.description"
							></TextAreaInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-if="serviceUuid"
					class="white--text"
					:loading="serviceButton"
					:disabled="!formValid || serviceButton"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onUpdateSubmit()"
				>
					Update Service
				</v-btn>
				<v-btn
					v-else
					class="white--text"
					:loading="serviceButton"
					:disabled="!formValid || serviceButton"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onSubmit()"
				>
					Service
				</v-btn>
				<v-btn depressed tile :disabled="serviceButton" v-on:click="$emit('close', true)">
					Close
				</v-btn>
			</template>
		</Dialog>
		<template v-if="manageServiceTypeDialog">
			<ManageServiceType
				:dialog="manageServiceTypeDialog"
				:serviceTypes="serviceTypeList"
				v-on:close-dialog="manageServiceTypeDialog = false"
				v-on:success="getServiceTypes"
			></ManageServiceType>
		</template>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import RadioInput from "@/view/components/RadioInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DateTimePicker from "@/view/components/DateTimePicker";
import { EventBus } from "@/core/event-bus/event.bus";
import ManageServiceType from "@/view/components/Manage-Service-Type.vue";
import MomentJS from "moment-timezone";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "service-template",
	title: "Service Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		serviceDialog: {
			type: Boolean,
			default: false,
		},
		serviceArray: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			formValid: true,
			serviceButton: false,
			pageLoading: false,
			manageServiceTypeDialog: false,
			serviceTypeList: [],
			vendorList: [],
			serviceUuid: null,
			memberList: [],
			service: {
				start_date: null,
				end_date: null,
				maintenance: null,
				service_type: null,
				performed_by: null,
				location_by: null,
				vendor: null,
				member: null,
				description: null,
			},
			performedItems: [
				{
					label: "Supplier",
					value: 1,
				},
				{
					label: "Staff",
					value: 2,
				},
			],
			performedPlaceItems: [
				{
					label: "Onsite",
					value: 1,
				},
				{
					label: "Workshop",
					value: 2,
				},
			],
		};
	},
	watch: {
		serviceArray(param) {
			if (param) {
				this.service = {
					start_date: param.expected_start_date,
					end_date: param.expected_end_date,
					maintenance: param.type == 1 ? true : false,
					service_type: param.service_type,
					performed_by: param.service_by,
					location_by: param.location_by,
					vendor: param.supplier,
					member: param.member,
					description: param.description,
				};
				this.serviceUuid = param.uuid;
			}
		},
	},
	components: {
		Dialog,
		RadioInput,
		DateTimePicker,
		ManageServiceType,
		TextAreaInput,
		AutoCompleteInput,
	},
	methods: {
		updateDates() {
			const start_date = new Date(this.service.start_date);
			const weekday = MomentJS(start_date).add(7, "days");
			this.service.end_date = this.formatDateTimeRaw(weekday);
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.serviceButton = true;

			ApiService.patch(`${this.endpoint}/${this.typeUuid}/service-schedule`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch(() => {
					this.$emit("error");
				})
				.finally(() => {
					this.serviceButton = false;
				});
		},
		onUpdateSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.serviceButton = true;

			ApiService.put(`asset/service-schedule/${this.serviceUuid}`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch(() => {
					this.$emit("error");
				})
				.finally(() => {
					this.serviceButton = false;
				});
		},
		getServiceTypes(data) {
			this.serviceTypeList = data;
		},
	},
	mounted() {
		const today = new Date();
		this.service.start_date = this.formatDateTimeRaw(today);
		this.serviceTypeList = this.localDB("service_types", []);
		this.vendorList = this.localDB("suppliers", []);
		this.memberList = this.localDB("members", []);
		this.$nextTick(() => {
			this.updateDates();
		});
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
